@import '../../statics/variables/index.scss';

.main-content {
  background-image: url(../../statics/assets/images/1310011.jpg);
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  animation: fadeIn 1s ease-in;
  &__redline {
    position: absolute;
    width: 700px;
    height: 236.68px;
    left: -200px;
    top: 240px;
    z-index: 7;
    background-size: contain;
    background: url(../../statics/assets/images/redline-mob.png) no-repeat;
    animation: 2s redline cubic-bezier(0.775, 0.82, 0.565, 1);
  }
  &__cloud {
    z-index: 6;
    position: absolute;
    background: url(../../statics/assets/images/main-cloud.png) no-repeat;
    background-size: 100%;
    left: -100px;
    top: -50px;
    width: 701px;
    height: 456px;
  }
  &__black-lightning {
    z-index: 6;
    position: absolute;
    background: url(../../statics/assets/images/black-lightning.png) no-repeat;
    background-size: contain;
    left: -10px;
    top: -72.74px;
    width: 464px;
    height: 556px;
  }
  &__white-lightning {
    z-index: 5;
    position: absolute;
    background: url(../../statics/assets/images/white-lightning.png) no-repeat;
    background-size: contain;
    left: -25px;
    top: 6px;
    width: 792px;
    height: 445px;
  }
  .main-container {
    display: flex;
    justify-content: center;
    margin-top: 170px;

    .result {
      z-index: 999;
      color: #50a01f;
      font-family: $font-h6, $font-h1;
      font-size: 18px;
      text-align: center;
    }
    .result-time {
      padding-top: 70px;
      color: white;
      font-size: 26px;
      font-family: $font-h6, $font-h1;
    }
    &__logo-image {
      z-index: 999;
      display: flex;
      width: 320px;
      height: 320px;
      border: 1px dashed #7a7a7a;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      /* animation: scaleAnimation 2s infinite alternate; */
      &_gray-circle {
        flex-direction: column;
        border-radius: 50%;
        width: 258px;
        height: 258px;
        background: #252525;
        border: 10px solid #e50539;
        display: flex;
        /*         flex-wrap: wrap; */
        align-items: center;
        justify-content: center;
        .logo-plane {
          margin-bottom: 25px;
          rotate: -14deg;
        }
      }
    }
  }

  .bottom-container {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__logo-name {
      width: 172px;
      height: 44px;
    }
    &__main-text {
      padding-top: 12px;
      color: white;
      font-family: $font-h1;
      font-size: 22px;
      line-height: 116.7%;
      letter-spacing: -0.04em;
    }
    &__bottom-text {
      text-align: center;
      padding-top: 16px;
      line-height: 116.7%;
      letter-spacing: -0.04em;
      color: white;
      font-family: $font-h5;
      font-size: 16px;
    }
  }
  .button {
    margin-top: 34px;
    font-family: $font-h1;
    border: 3px solid #ffffff;
    font-size: 18px;
    color: white;
    width: 220px;
    height: 64px;
    border-radius: 50px;
  }
  .buttonPlay {
    animation-duration: 1s;
    animation-name: boun;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    background: url(../../statics/assets/images/inbtn2.png) no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 8;
    filter: drop-shadow(0px 0px 10px rgba(167, 2, 2, 0.8));
    top: 62px;
    align-items: center;
    font-family: $font-h6, $font-h1;
    font-size: 16px;
    color: white;
    width: 250px;
    height: 80px;
    border: none;
    --bs-btn-active-bg: rgba(167, 90, 255, 0);
    --bs-btn-active-border-color: rgba(167, 90, 255, 0);
    --bs-btn-hover-bg: #00000000;
    --bs-btn-hover-border-color: #6c757d00;
  }
}

@keyframes boun {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(15deg);
    transform: rotateX(15deg);
  }
  50% {
    transform: rotateY(-15deg);
    transform: rotateX(-15deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}

@keyframes redline {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@media (min-width: 300px) {
  .main-content {
    &__redline {
      left: -250px;
    }
    background-size: 400%;
    background-position-x: 70%;
    background-position-y: 60%;
  }
}
@media (min-width: 360px) {
  .main-content {
    &__redline {
      left: -230px;
    }
  }
}
@media (min-width: 380px) {
  .main-content {
    &__redline {
      left: -210px;
    }
  }
}
@media (min-width: 420px) {
  .main-content {
    &__redline {
      left: -180px;
    }
  }
}
@media (min-width: 470px) {
  .main-content {
    &__redline {
      left: -150px;
    }
  }
}
@media (min-width: 540px) {
  .main-content {
    &__redline {
      left: -115px;
    }
  }
}

@media (min-width: 580px) {
  .main-content {
    background-size: 200%;
    .left-cloud {
      display: none;
    }
    &__cloud {
      display: none;
    }
    &__white-lightning {
      display: none;
    }
    &__black-lightning {
      display: none;
    }
    .right-cloud {
      display: none;
    }
    &__redline {
      display: none;
    }
  }
}
@media (max-height: 770px) {
  .main-content {
    .bottom-container {
      margin-top: 24px;
      .button {
        margin-top: 20px;
      }
    }
  }
}

@media (max-height: 740px) {
  .main-content {
    .buttonPlay {
      top: 10px;
    }
    &__redline {
      top: 170px;
    }
  }
  .main-container {
    margin-top: 90px !important;
  }
  .main-content {
    .bottom-container {
      &__logo-name {
        z-index: 200;
      }
      margin-top: 24px;
      .button {
        margin-top: 30px;
      }
    }
  }
}
