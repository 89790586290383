@import '../../../statics/variables/index.scss';
.loading {
  background-image: url(../../../statics/assets/images/back-load.png);
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 100%;
  height: 100vh;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_logo {
      margin-bottom: 80px;
      width: 380px;
      height: 110px;
    }
    &_progress {
      width: 760px;
      height: 30px;
      background-color: #5f5f5f;
      border-radius: 20px;
    }
    &_progress-bar {
      height: 100%;
      background-color: $main-red;
      border-radius: 20px;
    }
  }
}

@media (max-width: 1440px) {
  .loading {
    background-size: cover;
  }
}

@media (max-width: 1290px) {
  .loading {
    &__content {
      &_logo {
        margin-bottom: 50px;
        width: 280px;
        height: 80px;
      }
      &_progress {
        width: 560px;
        height: 25px;
      }
      &_progress-bar {
        border-radius: 20px;
      }
    }
  }
}

@media (max-width: 840px) {
  .loading {
    &__content {
      &_logo {
        margin-bottom: 26px;
        width: 200px;
        height: 70px;
      }
      &_progress {
        width: 460px;
        height: 18px;
        border-radius: 16px;
      }
      &_progress-bar {
        border-radius: 16px;
      }
    }
  }
}
@media (max-width: 600px) {
  .loading {
    &__content {
      &_logo {
        margin-bottom: 26px;
        width: 180px;
        height: 60px;
      }
      &_progress {
        width: 360px;
        height: 18px;
        border-radius: 16px;
      }
      &_progress-bar {
        border-radius: 16px;
      }
    }
  }
}
@media (max-width: 400px) {
  .loading {
    &__content {
      &_logo {
        margin-bottom: 26px;
        width: 160px;
        height: 50px;
      }
      &_progress {
        width: 300px;
        height: 18px;
        border-radius: 16px;
      }
      &_progress-bar {
        border-radius: 16px;
      }
    }
  }
}
