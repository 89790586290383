@import '../../statics/variables/index.scss';
body {
  animation: fadeIn 1.5s ease-in;
}

@keyframes loaderAnimation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 1;
  }
}
