@font-face {
  font-family: GP-Bold;
  src: url('../fonts/Gotham-Pro/gothampro_bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: GP-Light;
  src: url('../fonts/Gotham-Pro/gothampro_light.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: GP-Medium;
  src: url('../fonts/Gotham-Pro/gothampro_medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: GP-Regular;
  src: url('../fonts/Gotham-Pro/gothampro_regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: GP-BlackItalic;
  src: url('../fonts/Gotham-Pro/gothampro_blackitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
